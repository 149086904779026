@use './colors' as colors;
@use './typography' as typography;

@mixin link {
  @include typography.caption1;

  text-decoration: inherit;
  color: colors.$system-accent;
}

@mixin container {
  padding: 0 5rem;
}

@mixin page-container {
  @include container;

  margin: 0 auto;
  height: 100%;
  width: 100%;
}

@mixin text-ellipsis {
  display: inline-block;
  overflow: hidden;
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
}

@mixin button-styles-reset {
  border: 0;
  background: inherit;
  font-size: inherit;
  padding: 0;
  margin: 0;
  cursor: pointer;
}
