@use '@/styles/var/typography' as typograhy;
@use '@/styles/var/colors' as colors;
@use '@/styles/var/ui' as ui;

$width: 17.5rem;

.trigger {
  @include ui.button-styles-reset;

  position: relative;
  display: grid;
  grid-template-columns: 1fr min-content;
  grid-template-rows: 1fr;
  justify-content: space-between;
  border: 0.063rem solid colors.$grey-outline;
  border-radius: 0.75rem;
  padding: 0.625rem 1rem;
  background-color: colors.$grey-light-background;
  min-width: $width;
  width: $width;
  max-width: $width;
}

.label {
  @include typograhy.caption1;

  position: absolute;
  left: 1rem;
  top: -0.65rem;
  background-color: colors.$grey-light-background;
  padding: 0 0.25rem;
  color: colors.$grey-secondary;
}

.children {
  @include typograhy.body1;
  @include ui.text-ellipsis;

  text-align: left;
}
