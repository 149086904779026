@use '@/styles/var/colors' as colors;

.container {
  height: 100vh;
  background-color: colors.$grey-light-background;
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: min-content 1fr;
  overflow: hidden;
}
